<script setup lang="ts">
import MtgText from "@/Games/Mtg/MtgText.vue";
import {computed} from "vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppText from "@/Components/Shared/text/AppText.vue";

type Props = {
    card: object;
};

const { card } = defineProps<Props>();

const setModel = computed(() => {
    return card.set && card.set.parent
        ? card.set.parent
        : card.set;
});
</script>

<template>
    <div class="flex flex-col gap-2">
        <MtgText
            v-if="card.tcg_card.mana_cost"
            :text="card.tcg_card.mana_cost"
        />

        <AppText v-if="card.tcg_card" size="small" color="grayLight">
            {{ card.tcg_card.type }}
        </AppText>

        <AppLink
            v-if="setModel"
            :href="setModel.path"
            size="small"
            class="font-semibold"
        >
            {{ setModel.name }}
        </AppLink>

        <div class="flex flex-col gap-1">
            <MtgText
                v-if="card.tcg_card && card.tcg_card.oracle_text"
                :text="card.tcg_card.oracle_text"
                class="text-sm"
            />
        </div>
    </div>
</template>
