<script setup lang="ts">
import MtgText from "@/Games/Mtg/MtgText.vue";
import {computed} from "vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import LorcanaText from "@/Games/Lorcana/LorcanaText.vue";
import LorcanaColors from "@/Games/Lorcana/icons/LorcanaColors.vue";
import LorcanaCost from "@/Games/Lorcana/icons/LorcanaCost.vue";

type Props = {
    card: object;
};

const { card } = defineProps<Props>();

const setModel = computed(() => {
    return card.set && card.set.parent
        ? card.set.parent
        : card.set;
});
</script>

<template>
    <div class="flex flex-col gap-2">
        <LorcanaText
            v-if="card.tcg_card.mana_cost"
            :text="card.tcg_card.mana_cost"
        />

        <div class="flex gap-1 items-center">
            <LorcanaColors
                v-if="card.tcg_card.ink"
                :name="card.tcg_card.ink"
            />

            <LorcanaCost
                v-if="card.tcg_card.cost"
                :cost="card.tcg_card.cost"
                :inkable="card.tcg_card.inkwell"
            />
        </div>

        <AppText v-if="card.tcg_card.type" size="small" color="grayLight">
            {{ card.tcg_card.type.join(", ") }}
        </AppText>

        <AppLink v-if="setModel" :href="setModel.path" size="small" class="font-semibold">
            {{ setModel.name }}
        </AppLink>

        <div v-if="card.tcg_card.text" class="text-sm">
            <LorcanaText :text="card.tcg_card.text" />
        </div>
    </div>
</template>
