<template>
    <svg viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="151" height="151" rx="75.5" fill="#9E9E9E"/>
        <path d="M76.1495 101.634L101.296 58.078H51.0027L76.1495 101.634Z" fill="black" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5399 36.844H57.2552V49.8922H47.7435L41.052 60.5199L45.5438 69.0543L33.9972 75.1315L26 59.9368L40.5399 36.844Z" fill="black" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M111.76 36H95.0446V49.0482H104.556L111.248 59.6758L106.756 68.2103L118.303 74.2874L126.3 59.0927L111.76 36Z" fill="black" fill-opacity="0.8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M89.3916 121.649L98.5612 107.674L87.6517 100.516L82.4338 108.469L69.8771 108.233L65.2056 99.7957L53.7903 106.116L62.1075 121.138L89.3916 121.649Z" fill="black" fill-opacity="0.8"/>
    </svg>

</template>
