<script setup lang="ts">
import MtgText from "@/Games/Mtg/MtgText.vue";
import {computed} from "vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import PokemonText from "@/Games/Pokemon/PokemonText.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppHeader from "@/Components/Shared/header/AppHeader.vue";

type Props = {
    card: object;
};

const { card } = defineProps<Props>();

const setModel = computed(() => {
    return card.set && card.set.parent
        ? card.set.parent
        : card.set;
});
</script>

<template>
    <div class="flex flex-col gap-2">
        <div class="flex gap-1 items-center">
            <PokemonText v-if="card.tcg_card.types" :text="card.tcg_card.types.join('')" />
            <AppText v-if="card.tcg_card.hp" size="small">
                {{ card.tcg_card.hp }} HP
            </AppText>
        </div>

        <AppText v-if="card.tcg_card" size="small" color="grayLight">
            {{ card.tcg_card.supertype }}
        </AppText>

        <AppLink
            v-if="setModel"
            :href="setModel.path"
            size="small"
            class="font-semibold"
        >
            {{ setModel.name }}
        </AppLink>

        <section v-if="card.tcg_card && card.tcg_card.abilities">
            <div class="flex flex-col gap-2 text-sm">
                <div v-for="ability in card.tcg_card.abilities">
                    <strong class="text-red-600">{{ ability.name }}</strong>
                    <PokemonText :text="ability.text"/>
                </div>
            </div>
        </section>

        <section v-if="card.tcg_card.supertype !== 'Pokémon' && card.tcg_card && card.tcg_card.rules">
            <div class="flex flex-col gap-4 text-sm">
                <div v-for="rule in card.tcg_card.rules">
                    <PokemonText :text="rule" />
                </div>
            </div>
        </section>
        <section v-else-if="card.tcg_card && card.tcg_card.attacks">
            <div class="flex flex-col gap-4">
                <div v-for="attack in card.tcg_card.attacks" class="flex flex-col gap-1 grow text-sm">
                    <PokemonText :text="attack.cost.join('')" />
                    <strong>{{ attack.name }}<span v-if="attack.damage"> - {{ attack.damage }}</span></strong>
                    <PokemonText :text="attack.text" class="text-sm" />
                </div>
            </div>
        </section>
    </div>
</template>
