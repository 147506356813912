<script setup lang="ts">
import {
    HoverCard,
    HoverCardTrigger,
    HoverCardContent,
} from "@/Components/Shared/hoverCard";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";
import { apiPathUrl } from "@/Utils/Helpers";
import { ref } from "vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppCardPopoverMtg from "@/Components/Shared/card/AppCardPopoverMtg.vue";
import AppCardPopoverLorcana from "@/Components/Shared/card/AppCardPopoverLorcana.vue";
import AppCardPopoverPokemon from "@/Components/Shared/card/AppCardPopoverPokemon.vue";
import AppBadge from "@/Components/Shared/badge/AppBadge.vue";

type Props = {
    cardId: string;
};

const { cardId } = defineProps<Props>();

const loading = ref(false);
const card = ref(null);

const handleOpen = () => {
    if (card.value) {
        return;
    }

    loading.value = true;
    axios.get(apiPathUrl(`/cards/get-by-id/${cardId}`)).then(data => {
        card.value = data.data;
        loading.value = false;
    });
};
</script>

<template>
    <HoverCard
        :open-delay="500"
        @update:open="handleOpen"
    >
        <HoverCardTrigger as-child>
            <slot />
        </HoverCardTrigger>
        <HoverCardContent side="top" variant="card">
            <AppLoader v-if="loading" />
            <div
                v-if="card && card.data"
                class="flex flex-col gap-3"
            >
                <div class="flex flex-col sm:flex-row gap-6">
                    <AppLink :href="card.data.path">
                        <figure class="w-[200px] shrink-0">
                            <AppImage
                                :src="card.data.image"
                                :alt="card.data.name"
                                placeholder
                                variant="card"
                            />
                        </figure>
                    </AppLink>

                    <div class="flex flex-col mt-2">
                        <AppTitle
                            as="h3"
                            size="xsmall"
                        >
                            <AppLink :href="card.data.path">
                                {{ card.data.name }}
                            </AppLink>
                        </AppTitle>

                        <AppCardPopoverMtg
                            v-if="card.data.game.slug === 'mtg'"
                            :card="card.data"
                        />
                        <AppCardPopoverLorcana
                            v-else-if="card.data.game.slug === 'lorcana'"
                            :card="card.data"
                        />
                        <AppCardPopoverPokemon
                            v-else-if="card.data.game.slug === 'pokemon'"
                            :card="card.data"
                        />
                    </div>
                </div>

                <AppButtonGroup variant="spaced">
                    <AppBadge :color="card.data.game.slug">
                        {{ card.data.game.slug }}
                    </AppBadge>

                    <AppLink
                        :href="card.data.path"
                        variant="buttonSmall"
                    >
                        Bekijk kaart →
                    </AppLink>
                </AppButtonGroup>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>
