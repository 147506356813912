<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
} from "@/Utils/component";
import PokemonIconWater from "@/Games/Pokemon/icons/PokemonIconWater.vue";
import PokemonIconDark from "@/Games/Pokemon/icons/PokemonIconDark.vue";
import PokemonIconMetal from "@/Games/Pokemon/icons/PokemonIconMetal.vue";
import PokemonIconFighting from "@/Games/Pokemon/icons/PokemonIconFighting.vue";
import PokemonIconGrass from "@/Games/Pokemon/icons/PokemonIconGrass.vue";
import PokemonIconPsychic from "@/Games/Pokemon/icons/PokemonIconPsychic.vue";
import PokemonIconFire from "@/Games/Pokemon/icons/PokemonIconFire.vue";
import PokemonIconThunder from "@/Games/Pokemon/icons/PokemonIconThunder.vue";
import PokemonIconColorless from "@/Games/Pokemon/icons/PokemonIconColorless.vue";

export type IconName = "Water" | "Darkness" | "Metal" | "Fighting" | "Grass" | "Psychic" | "Fire" | "Lightning" | "Colorless";
export type IconSize = "";
export type IconColor = "current" | "primary" | "none";

type Props = {
    name: IconName;
    size?: IconSize;
    color?: IconColor;
};

const { size = "regular", color = "current", name } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "inline-flex items-center justify-center shrink-0 w-5 h-5 basis-5 rounded-full mx-0.5",
    variants: {
        size: {
            "": "",
        },
        color: {
            current: "text-current fill-current",
            primary: "text-theme fill-theme",
            none: "fill-none",
        },
    },
};

const { aClass } = installAntlerComponent("mtg-icon", { size, color }, classes);
</script>

<template>
    <PokemonIconWater
        v-if="name === 'Water'"
        :class="aClass()"
    />
    <PokemonIconDark
        v-else-if="name === 'Darkness'"
        :class="aClass()"
    />
    <PokemonIconMetal
        v-else-if="name === 'Metal'"
        :class="aClass()"
    />
    <PokemonIconFighting
        v-else-if="name === 'Fighting'"
        :class="aClass()"
    />
    <PokemonIconGrass
        v-else-if="name === 'Grass'"
        :class="aClass()"
    />
    <PokemonIconPsychic
        v-else-if="name === 'Psychic'"
        :class="aClass()"
    />
    <PokemonIconFire
        v-else-if="name === 'Fire'"
        :class="aClass()"
    />
    <PokemonIconThunder
        v-else-if="name === 'Lightning'"
        :class="aClass()"
    />
    <PokemonIconColorless
        v-else-if="name === 'Colorless'"
        :class="aClass()"
    />
</template>
