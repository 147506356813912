<script setup lang="ts">
import { computed } from "vue";
import PokemonIcon from "@/Games/Pokemon/icons/PokemonIcon.vue";


type Props = {
    text: string;
};

const { text } = defineProps<Props>();

// List of Pokémon-related keywords to parse into icons
const pokemonKeywords = [
    "Fighting",
    "Colorless",
    "Darkness",
    "Fire",
    "Grass",
    "Metal",
    "Psychic",
    "Lightning",
    "Water",
];

// This will split the text into words and preserve the words that match the Pokémon keywords
const segments = computed(() => {
    const regex = new RegExp(`(${pokemonKeywords.join("|")})`, "g");
    return text.split(regex);
});

const isPokemonIcon = (segment: string) => {
    return pokemonKeywords.includes(segment);
};
</script>

<template>
    <div>
        <template
            v-for="(segment, index) in segments"
            :key="index"
        >
            <!-- Render PokemonIcon component if the segment is a keyword -->
            <PokemonIcon
                v-if="isPokemonIcon(segment)"
                :name="segment"
            />
            <!-- Otherwise render the plain text -->
            <span v-else>{{ segment }}</span>
        </template>
    </div>
</template>
